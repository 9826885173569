import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import NavButtons from 'components/Advanced_User/NavButtons';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
export const _frontmatter = {
  "title": "Website integration - MJPEG Stream for WQHD cameras",
  "path": "/Advanced_User/Website_Integration/MJPEG_Stream_WQHD_Cameras/",
  "dateChanged": "2023-01-16",
  "author": "Mike Polinowski",
  "excerpt": "Insert the MJPEG live video from your WQHD 2k+ camera into your website.",
  "image": "./AU_SearchThumb_WebsiteIntegration_HD_Cams.png",
  "social": "/images/Search/AU_SearchThumb_WebsiteIntegration_HD_Cams.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_Advanced_Integration_white.webp",
  "chapter": "Advanced User"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <SEOHelmet title='Website integration - MJPEG Stream for WQHD cameras' dateChanged='23-01-16' author='Mike Polinowski' tag='INSTAR IP Camera' description='Insert the MJPEG live video from your WQHD 2k+ camera into your website.' image='/images/Search/AU_SearchThumb_WebsiteIntegration_HD_Cams.png' twitter='/images/Search/AU_SearchThumb_WebsiteIntegration_HD_Cams.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Erweitert/Webseiten_Einbindung/MJPEG_Stream_WQHD_Cameras/' locationFR='/fr/Advanced_User/Website_Integration/MJPEG_Stream_WQHD_Cameras/' crumbLabel="Website" mdxType="BreadCrumbs" />
    <NavButtons mdxType="NavButtons" />
    <h1 {...{
      "id": "website-integration---mjpeg-stream-for-wqhd-cameras",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#website-integration---mjpeg-stream-for-wqhd-cameras",
        "aria-label": "website integration   mjpeg stream for wqhd cameras permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Website integration - MJPEG stream for WQHD cameras`}</h1>
    <h3 {...{
      "id": "adding-the-mjpeg-videostream",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#adding-the-mjpeg-videostream",
        "aria-label": "adding the mjpeg videostream permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Adding the MJPEG Videostream`}</h3>
    <p>{`If you want to use the `}<a parentName="p" {...{
        "href": "/en/1440p_Series_CGI_List/#mjpeg-stream-livestreamactionplaymediamjpeg"
      }}>{`MJPEG stream`}</a>{` instead of the `}<a parentName="p" {...{
        "href": "/en/Advanced_User/Website_Integration/WS_Stream_WQHD_Cameras/"
      }}>{`h.264/h.265 Websocket Stream`}</a>{`, please modify the `}<a parentName="p" {...{
        "href": "https://wiki.instar.com/dl/Developer/"
      }}>{`mjpeg_video_wqhd.html`}</a>{` file (for Full HD cameras please use the `}<a parentName="p" {...{
        "href": "https://wiki.instar.com/dl/Developer/"
      }}>{`mjpeg_video.html`}</a>{` file instead - see `}<a parentName="p" {...{
        "href": "/en/Advanced_User/Website_Integration/HD_Camera_Integration/#adding-the-mjpeg-videostream"
      }}>{`Adding your Full HD Kameras Videostream to your Website`}</a>{`):`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "js"
    }}><pre parentName="div" {...{
        "className": "language-js"
      }}><code parentName="pre" {...{
          "className": "language-js"
        }}><span parentName="code" {...{
            "className": "token comment"
          }}>{`////////////////////////////////////////////////////////////////////////////////`}</span>{`
`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// DIESE PARAMETER M�SSEN ENTSPRECHEND AN DIE IHRER KAMERA ANGEPASST WERDEN`}</span>{`
`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// START KAMERA PARAMETER`}</span>{`
`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`////////////////////////////////////////////////////////////////////////////////`}</span>{`
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`var`}</span>{` url `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'192.168.2.120:80'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{` `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// Camera IP or DDNS`}</span>{`
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`var`}</span>{` protocol `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"http"`}</span>{` `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// http or https`}</span>{`
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`var`}</span>{` streamnum `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`13`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{` `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// Videostream user 11, 12 or 13`}</span>{`
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`var`}</span>{` name0 `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'admin'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{` `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// Camera Username`}</span>{`
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`var`}</span>{` password0 `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'instar'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{` `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// Camera Password`}</span>{`
`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`////////////////////////////////////////////////////////////////////////////////`}</span>{`
`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// ENDE KAMERA PARAMETER`}</span>{`
`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`////////////////////////////////////////////////////////////////////////////////`}</span></code></pre></div>
    <blockquote>
      <p parentName="blockquote"><strong parentName="p">{`Note`}</strong>{`: If your login contains a special character it has to be `}<a parentName="p" {...{
          "href": "https://www.w3schools.com/tags/ref_urlencode.ASP"
        }}>{`URL encoded`}</a>{`. For example the character `}<code parentName="p" {...{
          "className": "language-text"
        }}>{`&`}</code>{` encodes to `}<code parentName="p" {...{
          "className": "language-text"
        }}>{`%26`}</code>{` - if your camera login is `}<code parentName="p" {...{
          "className": "language-text"
        }}>{`&instar`}</code>{` you have to add `}<code parentName="p" {...{
          "className": "language-text"
        }}>{`%26instar`}</code>{` instead.`}</p>
    </blockquote>
    <h2 {...{
      "id": "testing-offline",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#testing-offline",
        "aria-label": "testing offline permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Testing Offline`}</h2>
    <p>{`If you want to verify that everything is working before you upload those files you need to use a local web server on your host machine to serve them to your web browser. If you already have an Apache or NGINX web server / proxy installed, just drop your file into the working directory of those applications and rename it `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`index.html`}</code>{`. You should now be able to access the website on your host machines IP address.`}</p>
    <p>{`As an alternative you can install `}<a parentName="p" {...{
        "href": "https://nodejs.org/en/"
      }}>{`Node.js on your computer`}</a>{`. Then install and run the program `}<strong parentName="p">{`httpster`}</strong>{` by typing the following command into your terminal inside the folder that holds your files (rename the one that you want to use to `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`index.html`}</code>{`):`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}><span parentName="code" {...{
            "className": "token function"
          }}>{`npm`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`install`}</span>{` -g httpster
httpster -p `}<span parentName="code" {...{
            "className": "token number"
          }}>{`3000`}</span></code></pre></div>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/0d46ecd5c4e02695f3fc3879d5d96df1/8ae78/WQHD_MJPEG_Stream_in_Website_01.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "35.21739130434782%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAHCAIAAACHqfpvAAAACXBIWXMAAA7rAAAO6wFxzYGVAAABW0lEQVQY0z3JbW+UMAAAYBK/arKYm8bt7qDA2lJaaEtL34ABMuQkt5vxm///hxiTxeT59kS89Vx75QcqdMl1KVoqTdtNtp8bN1BpqGjL9/qHcM1NUH6Qtot0mFSYVDdLN3DTt/13001ceao8077SgSnPlKeNK6UtpaWiFcIKYZm0ESxFI01dyYLJNC9SSDJIQI7jDMUZyjEllWRCQ8wOwX34MX5cp6/z+GUeM+MjRMXCtWMCU45JhcoKk4owwRtTCV1Lw7iiXBEqgLEPffi2zMdt/fTn9+fbHnXzGi7X4eetv1zN9BKWzYyLcIMIow7PyvZSe65drWyjnNbe++deunhb714vkdyv8PVXvt8e1wvY9ux6Q67rEXaI5JjFpAZFDZ4oeKIpZCliOeEwLw/LdPe2Rw8AHWN4jOE5QacEPcYwTlAOcJ6gc4pPWXFKixPA/x0BTs7wPvjD9vIXkJVQbXVB6osAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/0d46ecd5c4e02695f3fc3879d5d96df1/e4706/WQHD_MJPEG_Stream_in_Website_01.avif 230w", "/en/static/0d46ecd5c4e02695f3fc3879d5d96df1/d1af7/WQHD_MJPEG_Stream_in_Website_01.avif 460w", "/en/static/0d46ecd5c4e02695f3fc3879d5d96df1/7f308/WQHD_MJPEG_Stream_in_Website_01.avif 920w", "/en/static/0d46ecd5c4e02695f3fc3879d5d96df1/8ad02/WQHD_MJPEG_Stream_in_Website_01.avif 1096w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/0d46ecd5c4e02695f3fc3879d5d96df1/a0b58/WQHD_MJPEG_Stream_in_Website_01.webp 230w", "/en/static/0d46ecd5c4e02695f3fc3879d5d96df1/bc10c/WQHD_MJPEG_Stream_in_Website_01.webp 460w", "/en/static/0d46ecd5c4e02695f3fc3879d5d96df1/966d8/WQHD_MJPEG_Stream_in_Website_01.webp 920w", "/en/static/0d46ecd5c4e02695f3fc3879d5d96df1/7f3f2/WQHD_MJPEG_Stream_in_Website_01.webp 1096w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/0d46ecd5c4e02695f3fc3879d5d96df1/81c8e/WQHD_MJPEG_Stream_in_Website_01.png 230w", "/en/static/0d46ecd5c4e02695f3fc3879d5d96df1/08a84/WQHD_MJPEG_Stream_in_Website_01.png 460w", "/en/static/0d46ecd5c4e02695f3fc3879d5d96df1/c0255/WQHD_MJPEG_Stream_in_Website_01.png 920w", "/en/static/0d46ecd5c4e02695f3fc3879d5d96df1/8ae78/WQHD_MJPEG_Stream_in_Website_01.png 1096w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/0d46ecd5c4e02695f3fc3879d5d96df1/c0255/WQHD_MJPEG_Stream_in_Website_01.png",
              "alt": "HD Camera Integration",
              "title": "HD Camera Integration",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`By typing `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`httpster -p 3000`}</code>{` you now have started a web server on port 3000 on your host machine. This server is hosting the content of the folder you started it in. Since you renamed your file `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`index.html`}</code>{` your browser will see this as the entry point of your web site when accessing the URL `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`http://localhost:3000`}</code>{`:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/ae2a72e4cac124d722fb42fe3c6615ee/5148a/WQHD_MJPEG_Stream_in_Website_02.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "60.86956521739131%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAMCAIAAADtbgqsAAAACXBIWXMAAA7rAAAO6wFxzYGVAAAC50lEQVQozwHcAiP9ADEtNDw8Rj9ASTs7RSoqMiMkKiAhKBkZIR8fJh0dJRwbJB4dJhkXHxIRGREQGBEQGBMRGRQSGxYUHRgXHwBOTVZJRUw3KDY6LThMRUs4KjY6LzdwbmhEPkNRTU9iYl5PUU1obWqkp6i2triwsbOdpaCOm5GUm5a9u78AUUxVSkBKMRYoOyg1RjdBLxIkTj9DqKiXRTk/YVxYhIl9sLeu9Pfz/////v7+//7/////3fHcz9vL//7/AE5IUUAyPy8XJz0xPD0tOTEVJGVeW6mrmzgsNIaIfePu2P7//f79/vv6/P7+/////uv55qOxn52im/z8/ABNRlA5JTQyGClDOUVAKzcyFiR7eHGlppkyJjCgpZXq9trx+eb6//X3/fLd49m0vbGTnpR2fnmco6D///8AT0dSNiAwNR0tQDZCOyQyNBonkZGKsraoMyUvamhmdXZuipCEoKaXgoqAeHdwen95maykhZKNoqqo////AEo+SzQcLDkkNEE2QzshLjIaJ6OmnbK1qS8gK1pSVmVXVVRNS4WCelZPUXpzcoSIhnmKimJuc5elrP///wA6KjszFSc+LD5IPkw7IC04Ii+xt66pq6IvIi1nYmdvWFpeTE6CfXdxaXB5dnt6gIRndHmBlJ/Z5+////8APCo8MxUrOyo7QTVEORsqPCo0vMO4nJqUMSYxdXuFbl9lVkxQcWtqp6+4iJCZkKStboSJsMTM8Pn+//79AEIvQTUXKzYlNzgpOjwcKzonM7W8s4+LhjAmMmFeaFlETFJPU3p8dnBtdlBOV2FqcXGCi2t7hY+apP///wA7JjowESYyIzM0IzM2FSUzHSmYjIJ2b28sIi1bVWBHMDZDPUBiYFsyISsxKTE7OEBPUlw1N0J8f4f///8AWkZbUzlMY1ZgZVhgVTpIYE5VsaWXf3d2VUpSoaOeko2Ff4J7lJqOhYV/eXh2fH17hoiFeXx+rbGz////e8Q5ZeVfqSgAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/ae2a72e4cac124d722fb42fe3c6615ee/e4706/WQHD_MJPEG_Stream_in_Website_02.avif 230w", "/en/static/ae2a72e4cac124d722fb42fe3c6615ee/d1af7/WQHD_MJPEG_Stream_in_Website_02.avif 460w", "/en/static/ae2a72e4cac124d722fb42fe3c6615ee/7f308/WQHD_MJPEG_Stream_in_Website_02.avif 920w", "/en/static/ae2a72e4cac124d722fb42fe3c6615ee/9dc19/WQHD_MJPEG_Stream_in_Website_02.avif 1228w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/ae2a72e4cac124d722fb42fe3c6615ee/a0b58/WQHD_MJPEG_Stream_in_Website_02.webp 230w", "/en/static/ae2a72e4cac124d722fb42fe3c6615ee/bc10c/WQHD_MJPEG_Stream_in_Website_02.webp 460w", "/en/static/ae2a72e4cac124d722fb42fe3c6615ee/966d8/WQHD_MJPEG_Stream_in_Website_02.webp 920w", "/en/static/ae2a72e4cac124d722fb42fe3c6615ee/d565e/WQHD_MJPEG_Stream_in_Website_02.webp 1228w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/ae2a72e4cac124d722fb42fe3c6615ee/81c8e/WQHD_MJPEG_Stream_in_Website_02.png 230w", "/en/static/ae2a72e4cac124d722fb42fe3c6615ee/08a84/WQHD_MJPEG_Stream_in_Website_02.png 460w", "/en/static/ae2a72e4cac124d722fb42fe3c6615ee/c0255/WQHD_MJPEG_Stream_in_Website_02.png 920w", "/en/static/ae2a72e4cac124d722fb42fe3c6615ee/5148a/WQHD_MJPEG_Stream_in_Website_02.png 1228w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/ae2a72e4cac124d722fb42fe3c6615ee/c0255/WQHD_MJPEG_Stream_in_Website_02.png",
              "alt": "HD Camera Integration",
              "title": "HD Camera Integration",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <h2 {...{
      "id": "upload-the-page-to-your-server",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#upload-the-page-to-your-server",
        "aria-label": "upload the page to your server permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Upload the Page to your Server`}</h2>
    <p>{`Now please use a FTP client in order to upload the files to your web space. In our example we are using the software `}<strong parentName="p">{`FileZilla`}</strong>{`:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "700px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/4c164a39e166ca5b5e58f53b795344d5/29d31/WQHD_MJPEG_Stream_in_Website_03.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "42.608695652173914%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAJABQDASIAAhEBAxEB/8QAFwAAAwEAAAAAAAAAAAAAAAAAAAIDBf/EABQBAQAAAAAAAAAAAAAAAAAAAAD/2gAMAwEAAhADEAAAAdphyZQP/8QAGRAAAgMBAAAAAAAAAAAAAAAAAAEQESFC/9oACAEBAAEFAkijDqP/xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAEDAQE/AT//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAECAQE/AT//xAAWEAADAAAAAAAAAAAAAAAAAAAAIEH/2gAIAQEABj8CIv8A/8QAGxAAAQUBAQAAAAAAAAAAAAAAAAERIWGREHH/2gAIAQEAAT8hQp/BkxgamCdf/9oADAMBAAIAAwAAABCjz//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQMBAT8QP//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQIBAT8QP//EAB0QAAIBBAMAAAAAAAAAAAAAAAABMRARQVFxgcH/2gAIAQEAAT8QhNPpiJd3MdCObvwWaf/Z')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/4c164a39e166ca5b5e58f53b795344d5/e4706/WQHD_MJPEG_Stream_in_Website_03.avif 230w", "/en/static/4c164a39e166ca5b5e58f53b795344d5/d1af7/WQHD_MJPEG_Stream_in_Website_03.avif 460w", "/en/static/4c164a39e166ca5b5e58f53b795344d5/70e80/WQHD_MJPEG_Stream_in_Website_03.avif 700w"],
              "sizes": "(max-width: 700px) 100vw, 700px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/4c164a39e166ca5b5e58f53b795344d5/a0b58/WQHD_MJPEG_Stream_in_Website_03.webp 230w", "/en/static/4c164a39e166ca5b5e58f53b795344d5/bc10c/WQHD_MJPEG_Stream_in_Website_03.webp 460w", "/en/static/4c164a39e166ca5b5e58f53b795344d5/426ac/WQHD_MJPEG_Stream_in_Website_03.webp 700w"],
              "sizes": "(max-width: 700px) 100vw, 700px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/4c164a39e166ca5b5e58f53b795344d5/e83b4/WQHD_MJPEG_Stream_in_Website_03.jpg 230w", "/en/static/4c164a39e166ca5b5e58f53b795344d5/e41a8/WQHD_MJPEG_Stream_in_Website_03.jpg 460w", "/en/static/4c164a39e166ca5b5e58f53b795344d5/29d31/WQHD_MJPEG_Stream_in_Website_03.jpg 700w"],
              "sizes": "(max-width: 700px) 100vw, 700px",
              "type": "image/jpeg"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/4c164a39e166ca5b5e58f53b795344d5/29d31/WQHD_MJPEG_Stream_in_Website_03.jpg",
              "alt": "HD Camera Integration",
              "title": "HD Camera Integration",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`All you need to do is to link `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`mjpeg_video_wqhd.html`}</code>{` (rename this file according to your needs) - into your website.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      